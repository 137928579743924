import { createStore, useStore } from "@fscrypto/state-management";

const store = createStore<{ open: boolean }>({ open: false });
export const useRewardsDrawer = () => {
  const rewardDrawerStore = useStore(store);

  return {
    open: rewardDrawerStore.open,
    setOpen: (open: boolean) => store.set({ open }),
  };
};
